import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {isIE} from 'react-device-detect';
import Card from 'shared/components/card';
import GhostButton from 'shared/ui/atoms/button/ghost';
import PrimaryButton from 'shared/ui/atoms/button/primary';
import TertiaryButton from 'shared/ui/atoms/button/tertiary';
import InputRadio from 'shared/ui/atoms/input/radio';
import Radio from 'shared/ui/molecules/control/switch/radio';
import RadioGroup from 'shared/ui/molecules/input/radiogroup';
import api from 'candidate/api';
import i18n, {I18n} from 'candidate/i18n';
import AccountDetails from 'candidate/providers/account/details';
import Job from 'candidate/providers/job';
import ThankYou from 'candidate/shared/thankYou';
import sectionStyles from '../../form/section/styles.scss';
import {disabilityOptions, genderOptions, raceOptions, veteranOptions} from './options';
import styles from './styles.scss';

export const US_COUNTRY_CODE = 'US';

const UNDISCLOSED = 'undisclosed';

export const OpportunityEmployer = ({onSkip}) => (
  <Card.Content className={styles.opportunityEmployer}>
    <h4>{i18n.t('eeoc.opportunityemployer.header')}</h4>
    <p>{i18n.t('eeoc.opportunityemployer.description')}</p>
    {onSkip ? (
      <>
        <p>{i18n.t('eeoc.opportunityemployer.emailprompt')}</p>
        <TertiaryButton data-ui="skip-eeoc" onClick={onSkip}>
          {i18n.t('eeoc.skip')}
        </TertiaryButton>
      </>
    ) : null}
  </Card.Content>
);

OpportunityEmployer.propTypes = {
  onSkip: PropTypes.func
};

export const Race = () => (
  <>
    <Card.Annotations>
      <h4 className={sectionStyles.sectionName}>{i18n.t('eeoc.race.header')}</h4>
    </Card.Annotations>
    <div className={styles.content}>
      <RadioGroup className={styles.race} defaultValue={UNDISCLOSED} name="race" stacked>
        {raceOptions &&
          raceOptions.map(
            (option, index) =>
              option &&
              option.value && (
                <Radio
                  className={styles.options}
                  helper={option.explanation}
                  key={index}
                  labelWrap={false}
                  value={option.value}
                >
                  <h6>{option.label}</h6>
                </Radio>
              )
          )}
      </RadioGroup>
    </div>
  </>
);

export const Gender = () => (
  <>
    <Card.Annotations>
      <h4 className={sectionStyles.sectionName}>{i18n.t('eeoc.gender.header')}</h4>
    </Card.Annotations>
    <div className={styles.content}>
      <RadioGroup defaultValue={UNDISCLOSED} name="gender" stacked>
        {genderOptions &&
          genderOptions.map(
            (option, index) =>
              option &&
              option.value && (
                <InputRadio key={index} value={option.value}>
                  {option.label}
                </InputRadio>
              )
          )}
      </RadioGroup>
    </div>
  </>
);

export const Veteran = () => (
  <>
    <Card.Annotations>
      <h4 className={sectionStyles.sectionName}>{i18n.t('eeoc.veteran.header')}</h4>
    </Card.Annotations>
    <div className={styles.content}>
      <p>{i18n.t('eeoc.veteran.p1')}</p>
      <div>
        <p>{i18n.t('eeoc.veteran.disabledReasons.text')}</p>
        <ul>
          <li>{i18n.t('eeoc.veteran.disabledReasons.reasons.usMilitary')}</li>
          <li>{i18n.t('eeoc.veteran.disabledReasons.reasons.discharged')}</li>
          <li>{i18n.t('eeoc.veteran.disabledReasons.reasons.separated')}</li>
          <li>{i18n.t('eeoc.veteran.disabledReasons.reasons.active')}</li>
          <li>{i18n.t('eeoc.veteran.disabledReasons.reasons.armed')}</li>
        </ul>
      </div>
      <p>{i18n.t('eeoc.veteran.p2')}</p>
      <p>{i18n.t('eeoc.veteran.p3')}</p>
      <div className={styles.question}>
        <p>{i18n.t('eeoc.veteran.question.text')}</p>
        <RadioGroup className={styles.ofcpOptions} defaultValue={UNDISCLOSED} name="veteran" stacked>
          {veteranOptions &&
            veteranOptions.map(
              (option, index) =>
                option && (
                  <InputRadio key={index} value={option.value}>
                    <h6>{option.label}</h6>
                  </InputRadio>
                )
            )}
        </RadioGroup>
      </div>
      <p className={styles.faded}>{i18n.t('eeoc.veteran.p4')}</p>
      <p className={styles.faded}>{i18n.t('eeoc.veteran.p5')}</p>
    </div>
    <Card.Annotations>
      <h4 className={sectionStyles.sectionName}>{i18n.t('eeoc.voluntary.header')}</h4>
    </Card.Annotations>
    <div className={styles.content}>
      <p className={styles.faded}>
        {i18n.t('eeoc.voluntary.information.sentence1')}
        <br />
        {i18n.t('eeoc.voluntary.information.sentence2')}
        <br />
        {i18n.t('eeoc.voluntary.information.sentence3')}
      </p>
      <h5>{i18n.t('eeoc.voluntary.questions.question1.question')}</h5>
      <p>{i18n.t('eeoc.voluntary.questions.question1.answer.p1')}</p>
      <I18n Node="p" url="https://www.dol.gov/agencies/ofccp">
        eeoc.voluntary.questions.question1.answer.p2
      </I18n>

      <h5>{i18n.t('eeoc.voluntary.questions.question2.question')}</h5>
      <p>{i18n.t('eeoc.voluntary.questions.question2.answer.p1')}</p>
      <p>{i18n.t('eeoc.voluntary.questions.question2.answer.disabilities.text')}</p>
      <div className={styles.disabilities}>
        <I18n Node="ul">eeoc.voluntary.questions.question2.answer.disabilities.options1</I18n>
        <I18n Node="ul">eeoc.voluntary.questions.question2.answer.disabilities.options2</I18n>
        <I18n Node="ul">eeoc.voluntary.questions.question2.answer.disabilities.options3</I18n>
      </div>

      <p>{i18n.t('eeoc.voluntary.questions.question3.question')}</p>
      <RadioGroup className={styles.ofcpOptions} defaultValue={UNDISCLOSED} name="disability" stacked>
        {disabilityOptions &&
          disabilityOptions.map(
            (option, index) =>
              option && (
                <InputRadio key={index} value={option.value}>
                  <h6>{option.label}</h6>
                </InputRadio>
              )
          )}
      </RadioGroup>
      <p className={styles.faded}>{i18n.t('eeoc.voluntary.notice.p1')}</p>
    </div>
  </>
);

if (isIE) {
  require(/* webpackMode: "lazy", webpackChunkName: "formdata-polyfill" */ 'formdata-polyfill');
}

export const _Eeoc = ({countryCode, eeoc = false, err = null, id, ofccp = false, onSkip, onSubmit, withApplyState}) => {
  const [error, setError] = useState(err || null);
  const [skipped, setSkipped] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const _onSkip = useCallback(
    event => {
      if (onSkip) {
        onSkip(event);
      }

      setSkipped(true);
    },
    [onSkip]
  );

  if (!eeoc || (withApplyState && countryCode !== US_COUNTRY_CODE)) {
    return null;
  }

  const _onSubmit = event => {
    event.preventDefault();
    let answers = {};
    for (const [name, value] of new FormData(event.target).entries()) {
      if (value) {
        answers = {...answers, [name]: value};
      }
    }

    return api
      .eeoc(id, answers)
      .then(() => {
        if (onSubmit) {
          onSubmit();
        }

        setSubmitted(true);
        window.scrollTo(0, 0);
      })
      .catch(setError);
  };

  if (error) {
    throw error;
  }

  if (skipped || submitted) {
    return (
      <ThankYou
        footer={submitted ? i18n.t('form.success.footer.eeocSubmitted') : i18n.t('form.success.footer.skipped')}
        message={
          submitted ? i18n.t('form.success.message.eeocSubmitted') : i18n.t('form.success.message.formSubmitted')
        }
      />
    );
  }

  return (
    <form className={styles.eeoc} data-ui="eeoc-form" onSubmit={_onSubmit}>
      <Card hoverable={false}>
        <OpportunityEmployer onSkip={withApplyState ? _onSkip : undefined} />
        <Race />
        <Gender />
        {ofccp && <Veteran />}
        <Card.Footer className={styles.footer}>
          <PrimaryButton data-ui="submit-eeoc">{i18n.t('form.submit.survey')}</PrimaryButton>
          {onSkip && (
            <GhostButton data-ui="skip-eeoc-footer" onClick={onSkip}>
              {i18n.t('survey.skip')}
            </GhostButton>
          )}
        </Card.Footer>
      </Card>
    </form>
  );
};

_Eeoc.propTypes = {
  countryCode: PropTypes.string,
  eeoc: PropTypes.bool,
  err: PropTypes.object,
  id: PropTypes.string,
  ofccp: PropTypes.bool,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func,
  withApplyState: PropTypes.bool
};

const Eeoc = props => (
  <AccountDetails.Consumer>
    {({accountDetails: account}) => (
      <Job.Consumer>
        {({job}) =>
          account ? (
            <_Eeoc
              countryCode={job && job.location && job.location.countryCode}
              eeoc={account.details && account.details.eeoc}
              ofccp={account.details && account.details.ofccp}
              {...props}
            />
          ) : null
        }
      </Job.Consumer>
    )}
  </AccountDetails.Consumer>
);

export default Eeoc;
